.wrapper {
  @apply px-5 py-8 rounded-lg mt-6;
}

.light {
  @apply bg-none text-gray-900;
}

.dark {
  @apply bg-gray-800 text-gray-100 border-gray-900 border-b;
}

.purple {
  @apply bg-gradient-to-r from-purple-800 via-purple-700 to-purple-600 text-white border-black border-b-2 border-opacity-10 shadow;
}

.blue {
  @apply bg-gradient-to-r from-green-400 to-blue-500 text-white border-black border-b-2 border-opacity-10 shadow;
}

.inner_wrapper {
  @apply mx-auto text-xl py-6;
}

.inner_wrapper h1 {
  @apply font-extrabold;
}

.inner_wrapper h4 {
  @apply text-base mt-3;
}

.padding_large {
  @apply pb-16;
}

.padding_small {
  @apply py-3;
}
