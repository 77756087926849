.link {
  @apply flex space-x-2 items-center font-medium py-3 px-4 rounded  cursor-pointer border border-transparent;
}

.active,
.link.active:hover,
.link:hover {
  @apply bg-white text-slate-900 shadow border border-slate-300 shadow-slate-200;
}

.active {
  @apply cursor-default;
}
