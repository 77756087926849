.wrapper {
  @apply left-0 w-full bg-slate-100 text-slate-700 rounded md:flex items-center justify-between md:flex items-center md:space-x-2 space-y-2 md:space-y-0 mb-2 md:mb-0 mx-auto;
}

.wrapper button div span {
  @apply flex space-x-2;
}

.left {
  @apply flex space-x-2;
}

.right {
  @apply flex space-x-2;
}
